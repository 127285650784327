<template>
  <page-view :title="title">
    <a-card :bordered="false">
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="订单id"
        v-model="queryParam.id"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="订单号"
        v-model="queryParam.orderNo"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="姓名"
        v-model="queryParam.name"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="手机号"
        v-model="queryParam.mobile"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="快递公司"
        v-model="queryParam.waybillCompany"
      />
      <a-input
        style="width: 10%; margin-left: 8px;"
        placeholder="快递单号"
        v-model="queryParam.waybillNo"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="原快递公司"
        v-model="queryParam.oldWaybillCompany"
      />
      <a-input
        style="width: 10%; margin-left: 8px;"
        placeholder="原快递单号"
        v-model="queryParam.oldWaybillNo"
      />
      <span style="margin-left: 8px;">是否问题订单:</span>
      <a-select
        style="width: 10%; "
        placeholder="是否问题订单"
        :allowClear="true"
        v-model="queryParam.problemTime"
      >
        <a-select-option value="">全部</a-select-option>
        <a-select-option value="1">是</a-select-option>
        <a-select-option value="0">否</a-select-option>
      </a-select>
      <span style="margin-left: 8px;">解决状态:</span>
      <a-select
        style="width: 8%; margin-left: 8px;"
        placeholder="解决状态"
        :allowClear="true"
        v-model="queryParam.solveTime"
      >
        <a-select-option value="">全部</a-select-option>
        <a-select-option value="1">已解决</a-select-option>
        <a-select-option value="0">未解决</a-select-option>
      </a-select>
      <a-range-picker
        :placeholder="['创建时间开始', '创建时间结束']"
        style="width: 15%; margin-left: 8px;"
        @change="onChangeDatePay"
      />
      <a-range-picker
        :placeholder="['问题反馈时间开始', '问题反馈时间结束']"
        style="width: 15%; margin-left: 8px;"
        @change="onChangeDateProblem"
      />
      <a-button
        style="width: 7%; margin-left: 8px;"
        class="editable-add-btn"
        @click="$refs.table.refresh(false)"
        >查询</a-button
      >
      <s-table
        style="margin-top: 10px"
        ref="table"
        size="small"
        rowKey="id"
        :columns="columns"
        :data="dataSource"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="solveFile" slot-scope="text">
          <img
            :v-if="text"
            :src="text"
            @click="() => (preview = { show: true, image: text })"
            style="width: 45px;height: 45px;"
          />
        </template>
        <template slot="sellUid" slot-scope="text, record">
          <span v-if="record.solveTime === 0">未解决</span>
          <span v-else>已解决</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="solveEdit(record)">解决问题描述</a>
          <a @click="showOrder(record)" style="margin-left: 8px;">查看详情</a>
        </template>
      </s-table>
    </a-card>
    <a-modal
      title="提交问题"
      :width="640"
      :visible="solve.show"
      :maskClosable="false"
      @cancel="solveCancel"
      @ok="solveOk"
    >
      <p>订单号： {{ solve.orderNo }}</p>
      <p>快递公司： {{ solve.waybillCompany }}</p>
      <p>快递单号： {{ solve.waybillNo }}</p>
      <a-input class="top5" placeholder="问题描述" v-model="solve.solve" />
      <a-upload
        :multiple="false"
        :showUploadList="false"
        listType="picture"
        :customRequest="customRequest"
        accept="image/gif,image/png,image/jpeg,image/webp"
      >
        <img
          v-if="preview.image"
          :src="preview.image"
          style="width: 45px;height: 45px;"
        />
        <span v-else>上传图片</span>
      </a-upload>
    </a-modal>
    <a-modal
      :visible="preview.show"
      :footer="null"
      @cancel="() => (preview.show = false)"
    >
      <img style="width: 100%" :src="preview.image" />
    </a-modal>
    <a-modal
      :visible="order.show"
      :footer="null"
      @cancel="() => (order.show = false)"
    >
      <p>系统订单号： {{ order.content.no }}</p>
      <!--      <p>原快递公司： {{ order.record.oldWaybillCompany }}</p>-->
      <!--      <p>原快递单号： {{ order.record.oldWaybillNo }}</p>-->
      <p>快递公司： {{ order.record.waybillCompany }}</p>
      <p>快递单号： {{ order.record.waybillNo }}</p>
      <p>订单号： {{ order.record.orderNo }}</p>
      <p>收货人： {{ order.record.name }}</p>
      <p>联系电话： {{ order.record.mobile }}</p>
      <p>
        收货地址：
        {{
          order.record.province +
            order.record.city +
            order.record.county +
            order.record.address
        }}
      </p>
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import {
  orderDetailList,
  orderDetailUpdate,
  filePreUpload,
  uploadOSS,
  orderGet
} from "@api";
import { formatDate, uuid } from "@/utils/util";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

export default {
  data() {
    return {
      order: { show: false, content: {}, record: {} },
      preview: { image: "", show: false },
      solve: { show: false },
      queryParam: {},
      columns: [
        { title: "#", scopedSlots: { customRender: "serial" } },
        { title: "订单id", dataIndex: "id" },
        { title: "订单号", dataIndex: "orderNo" },
        { title: "T单号", dataIndex: "no" },
        { title: "姓名", dataIndex: "name" },
        { title: "手机号", dataIndex: "mobile" },
        // { title: "原快递公司", dataIndex: "oldWaybillCompany" },
        // { title: "原快递编号", dataIndex: "oldWaybillNo" },
        { title: "快递公司", dataIndex: "waybillCompany" },
        { title: "快递编号", dataIndex: "waybillNo" },
        {
          title: "创建时间",
          dataIndex: "createTime",
          customRender: formatDate
        },
        {
          title: "发货时间",
          dataIndex: "deliveryTime",
          customRender: formatDate
        },
        { title: "问题描述", dataIndex: "problem" },
        {
          title: "问题时间",
          dataIndex: "problemTime",
          customRender: formatDate
        },
        { title: "解决方案", dataIndex: "solve" },
        {
          title: "解决附件",
          dataIndex: "solveFile",
          scopedSlots: { customRender: "solveFile" }
        },
        { title: "反馈时间", dataIndex: "solveTime", customRender: formatDate },
        {
          title: "解决状态",
          dataIndex: "sellUid",
          scopedSlots: { customRender: "sellUid" }
        },
        {
          title: "操作",
          dataIndex: "comment",
          scopedSlots: { customRender: "operation" }
        }
      ],
      dataSource: parameter => {
        return orderDetailList(
          Object.assign(parameter, this.queryParam),
          2
        ).then(result => {
          return result.data;
        });
      }
    };
  },
  created() {},
  components: {
    PageView,
    STable
  },
  methods: {
    moment,
    showOrder(record) {
      this.order.content = {};
      orderGet(2, record.orderId).then(result => {
        if (result.success) {
          this.order = {
            show: true,
            content: result.data,
            record: record
          };
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    onChangeDatePay(date, dateString) {
      const time = dateString.includes("");
      if (time) {
        delete this.queryParam.createTimeStart;
        delete this.queryParam.createTimeEnd;
      } else {
        this.queryParam.createTimeStart = Date.parse(new Date(dateString[0]));
        this.queryParam.createTimeEnd = Date.parse(new Date(dateString[1]));
      }
      this.$refs.table.refresh(false);
    },
    onChangeDateProblem(date, dateString) {
      const time = dateString.includes("");
      if (time) {
        delete this.queryParam.problemTimeStart;
        delete this.queryParam.problemTimeEnd;
      } else {
        this.queryParam.problemTimeStart = Date.parse(new Date(dateString[0]));
        this.queryParam.problemTimeEnd = Date.parse(new Date(dateString[1]));
      }
      this.currentShortcutDate = null;
      this.$refs.table.refresh(false);
    },
    solveEdit(detail) {
      detail.show = true;
      this.preview.image = detail.solveFile;
      this.solve = JSON.parse(JSON.stringify(detail));
    },
    solveCancel() {
      this.solve = { show: false };
    },
    solveOk() {
      this.solve.solveFile = this.preview.image;
      orderDetailUpdate(this.solve, "solve").then(result => {
        if (result.success) {
          this.$message.success("反馈问题成功");
          this.solve = { show: false };
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;
      let today = new Date(new Date().toLocaleDateString()).getTime();
      let problemTimeEnd = today + 24 * 60 * 60 * 1000;
      let problemTimeStart = today - 24 * 60 * 60 * 1000 * record.date;
      if (record.date === 1) problemTimeEnd = today;
      this.queryParam.problemTimeStart = problemTimeStart;
      this.queryParam.problemTimeEnd = problemTimeEnd;
      this.$refs.table.refresh(false);
    },
    customRequest(fileData) {
      // TODO 1 上传到OSS
      filePreUpload()
        .then(response => {
          const data = response.data;
          const url = data.url;
          const prefix = data.key;
          delete data.url;
          delete data.key;
          const array = fileData.file.name.replace(" ", "").split("."); // 把文件名数组化
          let suffix = ""; // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`;
          }
          const formData = new FormData();
          Object.keys(data).forEach(it => {
            formData.append(it, data[it]);
          });
          const key = `${prefix}/${uuid()}${suffix}`; // 图片访问路径
          formData.append("key", key);
          formData.append("file", fileData.file);
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              this.preview.image = "http://czkj-cache.oss-cn-shenzhen.aliyuncs.com/" + key;
              this.$message.success("上传成功.");
              return fileData.onSuccess(); // 上传成功了
            })
            .catch(error => {
              this.$message.error("上传异常." + error);
              return fileData.onError(); // 拒绝上传操作
            });
        })
        .catch(error => {
          this.$message.error("授权异常." + error);
          return fileData.onError(); // 拒绝上传操作
        });
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.top5 {
  margin-bottom: 8px;
}
.time-select {
  display: inline-block;
  padding: 0;
  margin-left: 8px;
  li {
    list-style: none;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
  }
  li.active {
    color: #1890ff;
  }
}
</style>
